import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/esm/Nav";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import PortfolioImg from "../img/frvnk_webthumb.jpg";
import PortfolioImgLong from "../img/frvnk_cargobikethumb.jpg";

import { makeStyles } from "@material-ui/core/styles";

import Taxav1 from "../img/frvnk_taxatievergelijker1.jpg";
import Taxav2 from "../img/frvnk_taxatievergelijkerfw.jpg";
import Taxav3 from "../img/frvnk_taxatievergelijker3.jpg";
import Taxav4 from "../img/frvnk_taxatievergelijker4.jpg";

// import "./Projectdesctest.css";

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg1: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImgFW: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImg3: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg4: {
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "25px",
    width: "100%",
  },
  imageFW: {
    padding: "0",
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },

  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "white",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  rowOne: {
    marginBottom: "4vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
  recentProjects: {
    marginTop: "4vh",
  },
  text: {
    color: "var(--text-colorBlack)",
  },
}));

function ProjectdescTaxatievergelijker() {
  const classes = useStyles();

  return (
    <div data-aos="fade-up">
      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Vind dé taxateur in jouw regio
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className={classes.text}>
              Voor Taxatievergelijker heb ik het ontwerp van de website gemaakt.
              Op de website kun je een korte vragenlijst beantwoorden waarna je
              een top 3 van taxatieaanbieders in jouw regio te zien krijgt.
              Daarnaast heb ik het logo ontworpen. In het logo is een "zoek
              icon" verwerkt met een dak om het zoeken naar een taxateur voor
              vastgoed weer te geven.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={12}>
            <img className={classes.projectImg1} src={Taxav1} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Vergelijk offertes voordat je beslist.
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className={classes.imageFW}>
            <img className={classes.projectImgFW} src={Taxav2} alt=""></img>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Gemakkelijk de beste taxateur vinden
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className={classes.text}>
              Het doel van deze website is zeer simpel, zo snel en makkelijk
              mogelijk resultaat krijgen door een aantal vragen in te vullen. De
              content word op een speelse manier doormiddel van illustraties
              getoond aan de gebruiker.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Taxav3} alt=""></img>
          </Col>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Taxav4} alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Bekijk hier<br></br> mijn andere projecten
            </h1>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/Cargobike" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Applicatie | CargoDelivery
                  </h1>
                  <p className={classes.itemCategory}>Web applicatie</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImgLong}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer
              to="/PortfolioFrvnk"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Website | Portfolio 2021
                  </h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImg}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectdescTaxatievergelijker;
