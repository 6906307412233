import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PermMediaIcon from "@material-ui/icons/PermMedia";
import CreateIcon from "@material-ui/icons/Create";
import HttpIcon from "@material-ui/icons/Http";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Nav from "react-bootstrap/Nav";

import { LinkContainer } from "react-router-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "10%",
    marginBottom: "100px",
  },
  container: {
    backgroundColor: "var(--lightgrey-color)",
    marginTop: "12vh",
    marginBottom: "5vh",
    paddingTop: "2vh",
    paddingBottom: "8vh",
    transition: "0.3s ease-in",
  },
  icon: {
    verticalAlign: "middle",
    display: "inline-flex",
    color: "var(--primary-color)",
  },
  skilltitle: {
    fontWeight: "600",
    paddingLeft: "0%",
    padding: "0",
    marginTop: "2vh",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "52px",
    boxShadow: "none",
    marginBottom: "3vh",
    transition: "0.3s ease-in",
  },
  skillcat: {
    marginTop: "8%",
    paddingLeft: "0",
    paddingBottom: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    borderRadius: "0",
    fontSize: "16px",
    boxShadow: "none",
    transition: "0.3s ease-in",
  },
  SkillCard: {
    marginTop: "10%",
    paddingLeft: "0",
    paddingBottom: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    borderRadius: "0",
    fontSize: "16px",
    boxShadow: "none",
    transition: "0.3s ease-in",
  },
  SkillBox: {
    boxShadow: "none",
    transition: "0.3s ease-in",
  },
  SkillCardTitle: {
    fontWeight: "600",
    paddingLeft: "0%",
    padding: "0",
    marginTop: "15px",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    transition: "0.3s ease-in",
    fontSize: "32px",
    boxShadow: "none",
    marginBottom: "10px",
  },
  SkillCardDesc: {
    fontSize: "16px",
    marginTop: "15px",
    color: "var(--text-colorBlack)",
    transition: "0.3s ease-in",
  },
  button: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "3%",
    minWidth: "16vh",
    minHeight: "4vh",
    textTransform: "none",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      marginTop: "40px",
    },
  },
  link: {
    padding: "0",
  },
}));

function Skills() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div className={classes.container}>
      <Container>
        <Row>
          <Col sm={8}>
            <h2 data-aos="fade-up" className={classes.skillcat}>
              specialisaties
            </h2>
            <h1 data-aos="fade-up" className={classes.skilltitle}>
              Mijn specialisaties bestaan uit
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <PermMediaIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Offline media
              </h3>
              <h1 className={classes.SkillCardTitle}>Drukwerk</h1>
              <p className={classes.SkillCardDesc}>
                Visitekaartjes, posters, boeken, brochures, vlaggen en meer in
                combinatie met een krachtige visuele identiteit.
              </p>
            </div>
          </Col>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <CreateIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Brand Development
              </h3>
              <h1 className={classes.SkillCardTitle}>Huisstijlen</h1>
              <p className={classes.SkillCardDesc}>
                Het maken van een krachtige visuele identiteit waarmee elk
                bedrijf zich kan onderscheiden op de markt.
              </p>
            </div>
          </Col>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <HttpIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Webdesign
              </h3>
              <h1 className={classes.SkillCardTitle}>Webdevelopment</h1>
              <p className={classes.SkillCardDesc}>
                Met HTML, CSS en Javascript bouw ik snelle, interactieve
                websites. Dit kan ook met een CMS, API's of andere integraties
                zijn.
              </p>
            </div>
          </Col>
        </Row>

        <Row data-aos="fade-up">
          <Col sm>
            <LinkContainer to="/about">
              <Nav.Link href="about" className={classes.link}>
                <Button className={classes.button}>
                  Meer lezen{" "}
                  <ArrowForwardIosIcon
                    className={classes.icon}
                    style={{ fontSize: 16, marginLeft: "8px" }}
                  />
                </Button>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Skills;
