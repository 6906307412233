import React, { useEffect } from "react";

import HeaderNT from "./components/HeaderNT";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescNT from "./components/ProjectdescNT";

function Nivoba() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderNT />
      <ProjectdescNT />
    </div>
  );
}

export default Nivoba;
