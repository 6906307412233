import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";
import { LinkContainer } from "react-router-bootstrap";

import Nav from "react-bootstrap/esm/Nav";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import PortfolioImg from "../img/frvnk_keapsthumb.jpg";
import PortfolioImgLong from "../img/frvnk_cargobikethumb.jpg";

import Web1 from "../img/frvnk_web1.jpg";
import Web2 from "../img/frvnk_webfw.jpg";
import Web3 from "../img/frvnk_web2.jpg";
import Web4 from "../img/frvnk_web3.jpg";

// import "./Projectdesctest.css";

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg1: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImgFW: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImg3: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg4: {
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "25px",
    width: "100%",
  },
  imageFW: {
    padding: "0",
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "white",
    backgroundColor: "#151515",
    borderRadius: "0",
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },

  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "white",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  rowOne: {
    marginBottom: "4vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
  recentProjects: {
    marginTop: "4vh",
  },
}));

function ProjectdescWebsitefrvnk() {
  const classes = useStyles();

  return (
    <div data-aos="fade-up">
      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Tijd voor een nieuwe uitdaging
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Na een jaar werd het tijd voor een nieuw portfolio waar ik mijn
              recente werk op kan tonen. Na het project CargoDelivery, die
              ontwikkeld is door middel van React, leek het me leuk om mijzelf
              hier meer in te ontwikkelen. Hierom heb ik gekozen om mijn nieuwe
              portfolio te bouwen met React.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={12}>
            <img className={classes.projectImg1} src={Web1} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Design is a formal response to a strategic question.
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className={classes.imageFW}>
            <img className={classes.projectImgFW} src={Web2} alt=""></img>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>Requirements</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Deze website is door mij eerst ontworpen in Adobe XD waarna ik ben
              begonnen met het bouwen van de componenten in React. Daarnaast heb
              ik gebruik gemaakt van Material UI, EmailJS en React Bootstrap
              voor de grid.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Web3} alt=""></img>
          </Col>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Web4} alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Bekijk hier<br></br> mijn andere projecten
            </h1>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/Cargobike" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Applicatie | Cargodelivery
                  </h1>
                  <p className={classes.itemCategory}>Web applicatie</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImgLong}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer to="/Keaps" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>Website | Keaps</h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImg}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectdescWebsitefrvnk;
