import React, { useEffect } from "react";

import HeaderAbout from "./components/HeaderAbout";

import AOS from "aos";
import "aos/dist/aos.css";

import AboutpSec from "./components/AboutpSec";
import SkillsAbout from "./components/SkillsAbout";

function About() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderAbout />
      <AboutpSec />
      <SkillsAbout />
    </div>
  );
}

export default About;
