import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import "./Footer.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LogoKleur from "../img/LogoKleur.svg";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "var(--footer-color)",
    height: "100%",
    paddingTop: "12vh",
    paddingBottom: "12vh",
    borderBottom: "25px solid #151515",
  },
  test: {
    border: "1px solid red",
  },
  textFieldCustom: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      color: "white",
    },
  },
  footerTitle: {
    fontWeight: "600",
    padding: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "28px",
    boxShadow: "none",
    marginBottom: "20px",
    ["@media (max-width:780px)"]: {
      marginTop: "35px",
    },
  },
  footerText: {
    fontWeight: "300",
    padding: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "16px",
    boxShadow: "none",
    marginBottom: "20px",
    lineHeight: "1.6",
  },
  messageField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "60ch",
      color: "white",
      marginTop: "2vh",
    },
  },
  inputFields: {
    color: "white",
    "&.selected $MuiFilledInput-multiline": {
      height: "300px",
    },
  },
  logoFooter: {
    width: "20%",
  },
  button: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "5%",
    minWidth: "16vh",
    minHeight: "4vh",
    textTransform: "none",
  },
  firstLink: {
    marginTop: "0",
    color: "var(--text-colorBlack)",
    paddingLeft: "0",
    "&:hover": {
      color: "#412B76!important",
      fontWeight: "400",
    },
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginTop: "0vh",
      fontSize: "21px",
      marginLeft: "5px",
    },
  },
  logoNav: {
    backgroundImage: "var(--header-logo)",
    backgroundRepeat: "no-repeat",
    width: "75px",
    height: "75px",
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <div className="d-inline-block align-top">
                <div
                  // src={logoKleur}
                  // fill="white"
                  width="75"
                  height="75"
                  className={classes.logoNav}
                  // alt="Logo Frvnk"
                ></div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              {/* <h1 className={classes.footerTitle}>Links</h1> */}
              <h2 className={classes.footerTitle}>Contact</h2>
              <div className={classes.footerText}>
                <p>
                  Beneden Oosterdiep 131 Veendam,<br></br> 9641 JC Nederland
                </p>
                <p>Tel: +31 6 43 68 19 86</p>
                <p>Email: hallo@frvnk.nl</p>
              </div>
            </Col>
            <Col xs={12} md={2}>
              {/* <h1 className={classes.footerTitle}>Links</h1> */}
              <h2 className={classes.footerTitle}>Links</h2>
              <div className={classes.footerText}>
                <LinkContainer to="/home" className={classes.firstLink}>
                  <Nav.Link>
                    <ArrowRightIcon
                      className={classes.icon}
                      style={{ fontSize: 20, marginRight: "2px" }}
                    />
                    Home
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/projects" className={classes.firstLink}>
                  <Nav.Link>
                    <ArrowRightIcon
                      className={classes.icon}
                      style={{ fontSize: 20, marginRight: "2px" }}
                    />
                    Projecten
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/about" className={classes.firstLink}>
                  <Nav.Link>
                    <ArrowRightIcon
                      className={classes.icon}
                      style={{ fontSize: 20, marginRight: "2px" }}
                    />
                    Over mij
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact" className={classes.firstLink}>
                  <Nav.Link href="contact" className={classes.firstLink}>
                    <ArrowRightIcon
                      className={classes.icon}
                      style={{ fontSize: 20, marginRight: "2px" }}
                    />
                    Contact
                  </Nav.Link>
                </LinkContainer>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <h2 className={classes.footerTitle}>Social</h2>
              <div className={classes.footerText}>
                <Nav.Link
                  href="https://www.linkedin.com/in/frank-van-meurs-0b06b717a/"
                  className={classes.firstLink}
                >
                  <LinkedInIcon
                    className={classes.icon}
                    style={{ fontSize: 20, marginRight: "8px" }}
                  />
                  Linkedin
                </Nav.Link>
                <Nav.Link
                  href="https://github.com/FrankvanMeurs"
                  className={classes.firstLink}
                >
                  <GitHubIcon
                    className={classes.icon}
                    style={{ fontSize: 20, marginRight: "8px" }}
                  />
                  Github
                </Nav.Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Footer;
