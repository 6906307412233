import React, { useEffect } from "react";

import HeaderOsv from "./components/HeaderOsv";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescOsv from "./components/ProjectdescOsv";

function Nivoba() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderOsv />
      <ProjectdescOsv />
    </div>
  );
}

export default Nivoba;
