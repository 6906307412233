import React, { useEffect } from "react";

import HeaderKeaps from "./components/HeaderKeaps";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescKeaps from "./components/ProjectdescKeaps";

function Keaps() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderKeaps />
      <ProjectdescKeaps />
    </div>
  );
}

export default Keaps;
