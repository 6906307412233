import React, { useEffect } from "react";

import HeaderCargobike from "./components/HeaderCargobike";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescCargobike from "./components/ProjectdescCargobike";

function Cargobike() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderCargobike />
      <ProjectdescCargobike />
    </div>
  );
}

export default Cargobike;
