import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";
import { LinkContainer } from "react-router-bootstrap";

import Nav from "react-bootstrap/esm/Nav";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import PortfolioImg from "../img/frvnk_keapsthumb.jpg";
import PortfolioImgLong from "../img/frvnk_jtfthumb.jpg";

import WP1 from "../img/frvnk_wp1.jpg";
import WP2 from "../img/frvnk_wpfw.jpg";
import WP3 from "../img/frvnk_wp3.jpg";
import WP4 from "../img/frvnk_wp4.jpg";

// import "./Projectdesctest.css";

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg1: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImgFW: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImg3: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg4: {
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "25px",
    width: "100%",
  },
  imageFW: {
    padding: "0",
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "white",
    backgroundColor: "#151515",
    borderRadius: "0",
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },

  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "white",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  rowOne: {
    marginBottom: "4vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
  recentProjects: {
    marginTop: "4vh",
  },
}));

function ProjectdescJTF() {
  const classes = useStyles();

  return (
    <div data-aos="fade-up">
      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>Wordpress thema met PHP</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              In jaar 2 heb ik vanuit school de opdracht gekregen om een eigen
              Wordpress thema te bouwen. Hiervoor had ik gekozen om een
              portfolio te bouwen waarbij je dynamisch in Wordpress projecten en
              teksten kan toevoegen.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={12}>
            <img className={classes.projectImg1} src={WP1} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Design is a formal response to a strategic question.
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className={classes.imageFW}>
            <img className={classes.projectImgFW} src={WP2} alt=""></img>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>Van ontwerp naar thema</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Om te beginnen heb ik een ontwerp gemaakt in Adobe XD, hierna heb
              ik deze uitgewerkt met HTML, CSS en bootstrap. Toen dit klaar was
              heb ik dit omgebouwd naar PHP files met de Wordpress PHP functies
              en Advanced Custom Fields.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={WP3} alt=""></img>
          </Col>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={WP4} alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Bekijk hier<br></br> mijn andere projecten
            </h1>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/JoinTheForce" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Rebranding | Join The Force
                  </h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImgLong}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer to="/Keaps" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>Website | Keaps</h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImg}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectdescJTF;
