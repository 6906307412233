import React from "react";
import { useState, useRef, useEffect } from "react";

import emailjs from "emailjs-com";

import { makeStyles } from "@material-ui/core/styles";

import "../components/Navbar.css";

import logoKleur from "../img/LogoKleur.svg";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import Button from "@material-ui/core/Button";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { LinkContainer } from "react-router-bootstrap";

import TextField from "@material-ui/core/TextField";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";

import { Sling as Hamburger } from "hamburger-react";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#AARRGGBB",
    border: "1.5px solid #412B76",
    color: "var(--text-navButton)",
    "&:hover": {
      backgroundColor: "#412B76",
      color: "white",
    },
    borderRadius: "25px",
    minWidth: "14vh",
    minHeight: "4vh",
    textTransform: "none",
    marginLeft: "30px",

    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginTop: "5vh",
      marginBottom: "2vh",
      marginLeft: "0",
      minWidth: "45vh",
    },
  },
  nav: {
    paddingTop: "15px",
    paddingBottom: "15px",
    backgroundColor: "#AARRGGBB",
    color: "var(--text-colorBlack)!important",
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      backgroundColor: "var(--nav-background)!important",
    },
  },
  linknav: {
    marginRight: "15px",
    marginTop: "5px",
    color: "var(--text-colorBlack)!important",
    "&:hover": {
      color: "rgb(127 92 212)!important",
      fontWeight: "400",
    },
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      fontSize: "32px",
      marginLeft: "5px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#F7F7F7",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonSend: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "5%",
    marginBottom: "5%",
    minWidth: "16vh",
    minHeight: "4vh",
    textTransform: "none",
  },
  modalTitle: {
    fontWeight: "600",
    paddingLeft: "0%",
    padding: "0",
    marginTop: "4vh",
    textAlign: "left",
    color: "#000000",
    fontSize: "52px",
    backgroundColor: "#26262600",
    boxShadow: "none",
    marginBottom: "2vh",
  },
  textFieldCustom: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      color: "white",
      borderBottom: "1px solid #412B76",
    },
  },
  messageField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
      color: "white",
      borderBottom: "1px solid #412B76",
    },
    marginTop: "3vh",
    width: "36ch",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      width: "25ch",
    },
  },
  contactForm: {
    marginBottom: "8vh",
  },
  toggle: {
    border: "none",
    color: "rgba(127, 92, 212, 1)!important",
  },
  firstLink: {
    marginRight: "15px",
    marginTop: "5px",
    color: "var(--text-colorBlack)!important",
    "&:hover": {
      color: "rgb(127 92 212)!important",
      fontWeight: "400",
    },
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginTop: "5vh",
      fontSize: "32px",
      marginLeft: "5px",
    },
  },
  logoNav: {
    backgroundImage: "var(--header-logo)",
    backgroundRepeat: "no-repeat",
    width: "75px",
    height: "75px",
  },
  darkButton: {
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginLeft: "5px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    "& .MuiTypography-root": {
      fontFamily: "proxima-nova",
      fontWeight: "300",
      fontSize: "15px",
      paddingLeft: "10px",
      // eslint-disable-next-line
      ["@media (max-width:768px)"]: {
        paddingLeft: "0px",
        fontSize: "18px",
        marginLeft: "0",
      },
    },
  },
}));

function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_9inui9k",
      "template_tcgo9fq",
      e.target,
      "user_xYLK8y5735GCuCoJUuVXQ"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
  e.target.reset();
}

function Navbar2() {
  const classes = useStyles();

  const [navBackground, setNavBackground] = useState(false);
  const navRef = useRef();
  navRef.current = navBackground;
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50;
      if (navRef.current !== show) {
        setNavBackground(show);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const [ismenuOpen, setmenuOpen] = useState(false);
  const toggle = () => setmenuOpen(!ismenuOpen);
  console.log(ismenuOpen);

  var icon = document.getElementById("icon");

  const getDark = () => {
    document.body.classList.toggle("dark-theme");
  };

  return (
    <div>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light"
        className={classes.nav}
        fixed="top"
        style={{
          transition: "1s ease",
          backgroundColor: navBackground
            ? "var(--nav-background)"
            : "var(--transparant-color)",
        }}
      >
        <Container>
          <LinkContainer to="/home">
            <Navbar.Brand>
              <div className="d-inline-block align-top">
                <div
                  // src={logoKleur}
                  // fill="white"
                  width="75"
                  height="75"
                  className={classes.logoNav}
                  // alt="Logo Frvnk"
                ></div>
              </div>
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={classes.toggle}
            onClick={toggle}
          >
            <Hamburger size={36} easing="ease-in" />
          </Navbar.Toggle>

          {/* <button
            className="menu-toggle"
            onClick={toggle}
            aria-controls="responsive-navbar-nav"
          >
            <span className={`menu ${ismenuOpen ? "cross" : "hamburger"}`}>
              <Hamburger size={36} easing="ease-in" />
            </span>
          </button> */}

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <LinkContainer to="/home">
                <Nav.Link className={classes.firstLink} onClick={toggle}>
                  home
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/projects">
                <Nav.Link className={classes.linknav} onClick={toggle}>
                  projecten
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link className={classes.linknav} onClick={toggle}>
                  over mij
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link className={classes.linknav} onClick={toggle}>
                  contact
                </Nav.Link>
              </LinkContainer>

              <div>
                <Button
                  type="button"
                  onClick={handleOpen}
                  className={classes.button}
                >
                  hallo@frvnk.nl
                </Button>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className={classes.paper}>
                      <Container>
                        <Row>
                          <Col>
                            <h1
                              className={classes.modalTitle}
                              id="transition-modal-title"
                            >
                              Laten we samenwerken
                            </h1>
                          </Col>
                        </Row>
                        <form
                          onSubmit={sendEmail}
                          data-aos="fade-up"
                          id="contactFP"
                          className={classes.contactFP}
                        >
                          <Row>
                            <Col sm={4}>
                              <TextField
                                noValidate
                                autoComplete="off"
                                data-aos="fade-up"
                                name="from_name"
                                id="standard-basic"
                                label="Naam*"
                                InputProps={{
                                  className: classes.inputFields,
                                }}
                                InputLabelProps={{
                                  className: classes.floatingLabelFocusStyle,
                                  style: {
                                    color: "black",
                                  },
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <TextField
                                noValidate
                                autoComplete="off"
                                data-aos="fade-up"
                                id="standard-basic"
                                label="Email*"
                                name="from_email"
                                InputProps={{
                                  className: classes.inputFields,
                                }}
                                InputLabelProps={{
                                  className: classes.floatingLabelFocusStyle,
                                  style: {
                                    color: "black",
                                  },
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                className={classes.messageField}
                                noValidate
                                autoComplete="off"
                                data-aos="fade-up"
                                id="standard-multiline-static"
                                label="Jou bericht"
                                name="message"
                                multiline
                                rows={4}
                                placeholder="Typ hier jouw bericht"
                                InputProps={{
                                  className: classes.inputFields,
                                }}
                                InputLabelProps={{
                                  className: classes.floatingLabelFocusStyle,
                                  style: {
                                    color: "black",
                                  },
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div>
                                <Button
                                  onClick={handleClick}
                                  className={classes.buttonSend}
                                  type="submit"
                                >
                                  Bericht versturen{" "}
                                  <ArrowForwardIosIcon
                                    className={classes.icon}
                                    style={{ fontSize: 16, marginLeft: "8px" }}
                                  />
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </Container>
                    </div>
                  </Fade>
                </Modal>
              </div>

              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    className={classes.darkButton}
                    onClick={getDark}
                    value="top"
                    control={<Switch color="primary" />}
                    label="darkmode"
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbar2;
