import React, { useEffect } from "react";

import HeaderLogos from "./components/HeaderLogos";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescLogos from "./components/ProjectdescLogos";

function Nivoba() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderLogos />
      <ProjectdescLogos />
    </div>
  );
}

export default Nivoba;
