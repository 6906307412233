import React, { useEffect } from "react";

import HeaderPhotography from "./components/HeaderPhotography";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescPhotography from "./components/ProjectdescPhotography";

function Nivoba() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderPhotography />
      <ProjectdescPhotography />
    </div>
  );
}

export default Nivoba;
