import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "-40px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      marginTop: "-95px",
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "#ffffff",
    backgroundColor: "var(--primary-color)",
    transition: "0.3s ease-in",
    borderRadius: "0",
    fontSize: "24px",
    height: "100%",
    boxShadow: "none",
  },
  paper2: {
    padding: theme.spacing(5.5),
    textAlign: "center",
    color: "#ffffff",
    backgroundColor: "var(--secondary-color)",
    borderRadius: "0",
    fontSize: "21px",
    height: "100%",
    boxShadow: "none",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.2)",
    },
  },
  icon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  ctaLeft: {
    padding: "0",
  },
  ctaRight: {
    padding: "0",
  },
  link: {
    padding: "0",
  },
}));

function CallAction() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div className={classes.root}>
      <Container>
        <Row>
          <Col sm={12} md={8} className={classes.ctaLeft}>
            <Paper className={classes.paper}>
              Nieuwsgierig geworden? Samenwerken aan een tof project?
            </Paper>
          </Col>
          <Col sm={12} md={4} className={classes.ctaRight}>
            <LinkContainer to="/contact">
              <Nav.Link className={classes.link}>
                <Paper className={classes.paper2}>
                  Neem contact op!
                  <ArrowForwardIosIcon
                    className={classes.icon}
                    style={{ fontSize: 24, marginLeft: "5px" }}
                  />
                </Paper>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CallAction;
