import React, { useEffect } from "react";

import HeaderJTF from "./components/HeaderJTF";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescJTF from "./components/ProjectdescJTF";

function JTF() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderJTF />
      <ProjectdescJTF />
    </div>
  );
}

export default JTF;
