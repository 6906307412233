import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";

import Nav from "react-bootstrap/esm/Nav";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import PortfolioImg from "../img/frvnk_taxatievergelijkerthumb.jpg";
import PortfolioImgLong from "../img/frvnk_jtfthumb.jpg";

import Keaps1 from "../img/frvnk_keaps1.jpg";
import Keaps2 from "../img/frvnk_keapsfw.jpg";
import Keaps3 from "../img/frvnk_keaps3.jpg";
import Keaps4 from "../img/frvnk_keaps4.jpg";

import { LinkContainer } from "react-router-bootstrap";

// import "./Projectdesctest.css";

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg1: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImgFW: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImg3: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg4: {
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "25px",
    width: "100%",
  },
  imageFW: {
    padding: "0",
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "white",
    backgroundColor: "#151515",
    borderRadius: "0",
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },

  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "white",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  rowOne: {
    marginBottom: "4vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
  recentProjects: {
    marginTop: "4vh",
  },
}));

function ProjectdescKeaps() {
  const classes = useStyles();

  return (
    <div data-aos="fade-up">
      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Een betrouwbare ruimtebesparende oplossing
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Keaps ontwikkelde een volautomatisch parkeersysteem dat meer
              auto's kan stallen op minder vierkante meters. Om de
              parkeergarages op de markt te brengen was er een website nodig.
              Hiervoor heb ik de website ontworpen en gebouwd in Wordpress.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={12}>
            <img className={classes.projectImg1} src={Keaps1} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Veiliger, gemakkelijker en slimmer parkeren
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className={classes.imageFW}>
            <img className={classes.projectImgFW} src={Keaps2} alt=""></img>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Eenvoudig aan te sluiten parkeersystemen
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Het doel van de website is om mensen te informeren over de
              geautomatiseerde parkeergarages en uiteraard om ze te verkopen. Op
              de website is veel informatie te vinden over de systemen, hoe het
              werkt en de specificaties.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Keaps3} alt=""></img>
          </Col>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Keaps4} alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Bekijk hier<br></br> mijn andere projecten
            </h1>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/JoinTheForce" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Rebranding | Join The Force
                  </h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImgLong}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer
              to="/Taxatievergelijker"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Website | Taxatievergelijker
                  </h1>
                  <p className={classes.itemCategory}>Web design</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImg}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectdescKeaps;
