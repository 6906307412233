import React, { useEffect } from "react";

import HeaderTaxatievergelijker from "./components/HeaderTaxatievergelijker";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescTaxatievergelijker from "./components/ProjectdescTaxatievergelijker";

function Taxatievergelijker() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderTaxatievergelijker />
      <ProjectdescTaxatievergelijker />
    </div>
  );
}

export default Taxatievergelijker;
