import React, { useEffect } from "react";

import HeaderWebsitefrvnk from "./components/HeaderWebsitefrvnk";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescWebsitefrvnk from "./components/ProjectdescWebsitefrvnk";

function Websitefrvnk() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderWebsitefrvnk />
      <ProjectdescWebsitefrvnk />
    </div>
  );
}

export default Websitefrvnk;
