import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CreateIcon from "@material-ui/icons/Create";
import HttpIcon from "@material-ui/icons/Http";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "10%",
    marginBottom: "100px",
  },
  container: {
    backgroundColor: "var(--lightgrey-color)",
    marginTop: "12vh",
    paddingTop: "2vh",
    paddingBottom: "8vh",
  },
  icon: {
    verticalAlign: "middle",
    display: "inline-flex",
    color: "var(--primary-color)",
  },
  skilltitle: {
    fontWeight: "600",
    paddingLeft: "0%",
    padding: "0",
    marginTop: "2vh",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "52px",
    boxShadow: "none",
    marginBottom: "3vh",
  },
  skillcat: {
    marginTop: "8%",
    paddingLeft: "0",
    paddingBottom: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    borderRadius: "0",
    fontSize: "16px",
    boxShadow: "none",
  },
  SkillCard: {
    marginTop: "10%",
    paddingLeft: "0",
    paddingBottom: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    borderRadius: "0",
    fontSize: "16px",
    boxShadow: "none",
  },
  SkillCardTitle: {
    fontWeight: "600",
    paddingLeft: "0%",
    padding: "0",
    marginTop: "15px",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "32px",
    boxShadow: "none",
    marginBottom: "10px",
  },
  SkillCardDesc: {
    fontSize: "16px",
    marginTop: "15px",
    color: "var(--text-colorBlack)",
  },
  button: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "3%",
    minWidth: "16vh",
    minHeight: "4vh",
    textTransform: "none",
  },
  test: {
    border: "1px solid red",
  },
}));

function Skills() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div className={classes.container}>
      <Container>
        <Row>
          <Col sm={8}>
            <h2 data-aos="fade-up" className={classes.skillcat}>
              specialisaties
            </h2>
            <h1 data-aos="fade-up" className={classes.skilltitle}>
              4e Jaars <br></br>Student Front End Developer
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <FindInPageIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Research, Wireframing
              </h3>
              <h1 className={classes.SkillCardTitle}>
                UX <br></br> Design
              </h1>
              <p className={classes.SkillCardDesc}>
                Het ontwikkelen van producten die zinvolle en relevante
                ervaringen bieden voor de gebruiker.
              </p>
            </div>
          </Col>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <PlayArrowIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Animation, prototyping
              </h3>
              <h1 className={classes.SkillCardTitle}>
                Interaction <br></br> Design
              </h1>
              <p className={classes.SkillCardDesc}>
                Het ontwerpen van interactieve producten en diensten waarbij de
                focus ligt op de manier waarop de gebruiker ermee om zal gaan.
              </p>
            </div>
          </Col>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <CreateIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Visual, High Fidelity
              </h3>
              <h1 className={classes.SkillCardTitle}>
                UI <br></br> Design
              </h1>
              <p className={classes.SkillCardDesc}>
                Het creëren van interfaces die gebruikers gebruiksvriendelijk en
                plezierig vinden.
              </p>
            </div>
          </Col>
          <Col sm>
            <div data-aos="fade-up" className={classes.SkillBox}>
              <h3 className={classes.SkillCard}>
                <HttpIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                HTML, CSS, JS
              </h3>
              <h1 className={classes.SkillCardTitle}>
                Front-End <br></br> Development
              </h1>
              <p className={classes.SkillCardDesc}>
                Ontwikkeling van de grafische gebruikersinterface van een
                website, door het gebruik van HTML, CSS en JavaScript.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Skills;
