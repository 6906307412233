import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import HeaderProjects from "./components/HeaderProjects";

import { makeStyles } from "@material-ui/core/styles";

import ControlPointIcon from "@material-ui/icons/ControlPoint";

import AOS from "aos";
import "aos/dist/aos.css";

import TaxavThumb from "./img/frvnk_taxatievergelijkerthumb.jpg";
import CargobThumb from "./img/frvnk_cargobikethumb.jpg";
import KeapsThumb from "./img/frvnk_keapsthumb.jpg";
import JTFThumb from "./img/frvnk_jtfthumb.jpg";
import wpThumb from "./img/frvnk_wpthumb.jpg";
import WebThumb from "./img/frvnk_webthumb.jpg";
import ntThumb from "./img/frvnk_ntthumb.jpg";
import kiThumb from "./img/frvnk_kithumb.jpg";
import logosThumb from "./img/frvnk_logothumb.jpg";
import photographyThumb from "./img/frvnk_photographythumb.jpg";
import roosThumb from "./img/frvnk_roothumb.jpg";
import osvThumb from "./img/frvnk_osvthumb.jpg";

import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/esm/Nav";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "absolute",
    top: "31%",
    left: "15%",
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "white",
    backgroundColor: "#151515",
    borderRadius: "0",
    filter: "grayscale(1)",
    "&:hover": {
      filter: "saturate(1.5)",
    },
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    minWidth: "300px",
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },
  portItemL: {
    minWidth: "300px",
    height: "100%",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      marginTop: "15px",
    },
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },
  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "white",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  iconS: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      top: "38%",
    },
  },
  rowFirst: {
    marginTop: "4vh",
  },
  rowFour: {
    marginTop: "2vh",
  },
  rowThree: {
    marginTop: "2vh",
  },
  rowTwo: {
    marginTop: "2vh",
  },
  rowOne: {
    marginTop: "2vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
}));

function Projects() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderProjects />

      <Container className={classes.gridGallery} data-aos="fade-up">
        <Row className={classes.rowFirst}>
          <Col xs={12} md={4}>
            <LinkContainer to="/Osv" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItemL}>
                  <ControlPointIcon className={classes.iconS} />
                  <h1 className={classes.itemtTitle}>Website | OSV</h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={osvThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer to="/Roossien" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Website | Duurzaamheidstechniek Roossien
                  </h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={roosThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={8}>
            <LinkContainer to="/Photography" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>Other | Photography</h1>
                  <p className={classes.itemCategory}>Other</p>
                  <img
                    className={classes.portfolioimg}
                    src={photographyThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={4}>
            <LinkContainer to="/Logos" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItemL}>
                  <ControlPointIcon className={classes.iconS} />
                  <h1 className={classes.itemtTitle}>Collection | Logos</h1>
                  <p className={classes.itemCategory}>Other</p>
                  <img
                    className={classes.portfolioimg}
                    src={logosThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/Cargobike" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Applicatie | Cargodelivery
                  </h1>
                  <p className={classes.itemCategory}>Web applicatie</p>
                  <img
                    className={classes.portfolioimg}
                    src={CargobThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer
              to="/Taxatievergelijker"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItemL}>
                  <ControlPointIcon className={classes.iconS} />
                  <h1 className={classes.itemtTitle}>
                    Website | Taxatievergelijker
                  </h1>
                  <p className={classes.itemCategory}>Web design</p>
                  <img
                    className={classes.portfolioimg}
                    src={TaxavThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>

        <Row className={classes.rowTwo}>
          <Col xs={12} md={8}>
            <LinkContainer to="/Keaps" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.iconS} />
                  <h1 className={classes.itemtTitle}>Website | Keaps</h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={KeapsThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
          <Col xs={12} md={4}>
            <LinkContainer to="/JoinTheForce" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItemL}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Rebranding | Join The Force
                  </h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={JTFThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>

        <Row className={classes.rowThree}>
          <Col xs={12} md={4}>
            <LinkContainer to="/wpThemeFrvnk" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>Website | Portfolio</h1>
                  <p className={classes.itemCategory}>Wordpress Theme</p>
                  <img
                    className={classes.portfolioimg}
                    src={wpThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
          <Col xs={12} md={8}>
            <LinkContainer
              to="/PortfolioFrvnk"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItemL}>
                  <ControlPointIcon className={classes.iconS} />
                  <h1 className={classes.itemtTitle}>Website | Portfolio</h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={WebThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={8}>
            <LinkContainer
              to="/KappeIntermediair"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Branding | Kappe Intermediair
                  </h1>
                  <p className={classes.itemCategory}>Branding</p>
                  <img
                    className={classes.portfolioimg}
                    src={kiThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={4}>
            <LinkContainer
              to="/NivobaTechnology"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItemL}>
                  <ControlPointIcon className={classes.iconS} />
                  <h1 className={classes.itemtTitle}>
                    Branding | Nivoba Technology
                  </h1>
                  <p className={classes.itemCategory}>Web design</p>
                  <img
                    className={classes.portfolioimg}
                    src={ntThumb}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Projects;
