import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "bootstrap/dist/css/bootstrap.min.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import AboutImg from "../img/frvnk_about.png";
import Nav from "react-bootstrap/Nav";

import { LinkContainer } from "react-router-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // marginLeft: '15%',
    // marginRight: '15%',
    paddingTop: "140px",
  },
  aboutimg: {
    paddingLeft: "0",
    textAlign: "left",
    color: "white",
    borderRadius: "0",
    // fontSize: '24px',
    boxShadow: "none",
    width: "100%",
  },
  aboutcat: {
    marginTop: "10%",
    paddingBottom: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    transition: "0.3s ease-in",
    borderRadius: "0",
    fontSize: "16px",
    boxShadow: "none",
  },
  abouttitle: {
    fontWeight: "600",
    padding: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    transition: "0.3s ease-in",
    fontSize: "52px",
    backgroundColor: "#26262600",
    boxShadow: "none",
    marginBottom: "20px",
    marginTop: "10px",
  },
  abouttext: {
    textAlign: "left",
    color: "var(--text-colorBlack)",
    transition: "0.3s ease-in",
    borderRadius: "0",
    fontSize: "18px",
    boxShadow: "none",
  },
  button: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "5%",
    minWidth: "16vh",
    minHeight: "4vh",
    textTransform: "none",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      marginTop: "30px",
    },
  },
  icon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  link: {
    padding: "0",
  },
}));

function AboutmeSec() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div data-aos="fade-down" className={classes.root}>
      <Container>
        <Row>
          <Col xs={10} md={4}>
            <img className={classes.aboutimg} src={AboutImg} alt=""></img>
          </Col>

          <Col xs={12} md={8}>
            <h3 className={classes.aboutcat}>over mij</h3>
            <h1 className={classes.abouttitle}>
              Ik ontwerp en bouw digitale producten
            </h1>
            <p className={classes.abouttext}>
              Ik heb een passie voor user interface, animaties en het creëren
              van interessante gebruikerservaringen. Probleemoplosser en
              zelfstandig persoon met veel aandacht voor detail. Geïnteresseerd
              in het hele frontend, UX en UI design spectrum en het werken aan
              fantastische projecten met positieve mensen.
            </p>
            <LinkContainer to="/about">
              <Nav.Link className={classes.link}>
                <Button className={classes.button}>
                  Meer lezen{" "}
                  <ArrowForwardIosIcon
                    className={classes.icon}
                    style={{ fontSize: 16, marginLeft: "8px" }}
                  />
                </Button>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      {/* <Grid container spacing={1}>
            <Grid item md={5} xs={6}>
            <Paper className={classes.aboutimg}><img src={ AboutImg } alt=""></img></Paper>
            </Grid>
            <Grid item md={7} xs={8}>
            <h3 className={classes.aboutcat}>about</h3>
            <h1 className={classes.abouttitle}>Lorem ipsum dolor sit amet, 
                consectetuer adipiscing</h1>
            <p className={classes.abouttext}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,</p>
            <Button className={classes.button}>Meer lezen <ArrowForwardIosIcon className={classes.icon} style={{ fontSize: 16, marginLeft: '8px',}} /></Button>
            </Grid>
        </Grid> */}
    </div>
  );
}

export default AboutmeSec;
