import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { makeStyles } from "@material-ui/core/styles";

import AboutImg from "../img/frvnk_about.png";

import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

import Nav from "react-bootstrap/Nav";

import myCV from "../files/CV_FrankvanMeurs.pdf";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "absolute",
    top: "31%",
    left: "15%",
  },
  aboutTitle: {
    marginTop: "8vh",
    color: "var(--text-colorBlack)",
    // marginBottom: "4vh",
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginTop: "6vh",
    },
  },
  aboutImg: {
    paddingLeft: "0",
    textAlign: "left",
    borderRadius: "0",
    // fontSize: '24px',
    boxShadow: "none",
    width: "32vh",
  },
  aboutsubTitle: {
    fontWeight: "600",
    padding: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "28px",
    boxShadow: "none",
    marginBottom: "20px",
  },
  aboutDesc: {
    padding: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "18px",
    boxShadow: "none",
    marginBottom: "20px",
    paddingRight: "10vh",
    lineHeight: "21px",
  },
  aboutSection: {
    marginTop: "50px",
  },
  button: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "3%",
    minWidth: "17vh",
    minHeight: "4vh",
    textTransform: "none",
    paddingLeft: "17px",
    paddingRight: "17px",
  },
  link: {
    padding: "0",
  },
  firstLink: {
    marginTop: "0",
    color: "var(--text-colorBlack)",
    paddingLeft: "0",
    "&:hover": {
      color: "#412B76!important",
      fontWeight: "400",
    },
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginTop: "0vh",
      fontSize: "21px",
      marginLeft: "5px",
    },
  },
  socialLink: {
    marginTop: "20px",
    // eslint-disable-next-line
    ["@media (max-width:768px)"]: {
      marginTop: "20px",
      marginBottom: "20px",
    },
  },
}));

function AboutpSec() {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1 data-aos="fade-up" className={classes.aboutTitle}>
              4e jaars student<br></br> Communicatie & Multimedia
            </h1>
          </Col>
        </Row>
        <Row className={classes.aboutSection}>
          <Col data-aos="fade-up" sm={8}>
            {/* <h1 data-aos="fade-up" className={classes.aboutTitle}>
              3e jaars student<br></br> Communicatie & Multimedia
            </h1> */}
            <p className={classes.aboutDesc}>
              Hallo! Ik ben Frank van Meurs, 4e jaars student Communicatie &
              Multimedia uitstroom interaction design aan de Hanze Hogeschool in
              Groningen. <br></br>
              <br></br>Na het afronden van de middelbare school ben ik begonnen
              aan de opleiding Mediavormgeving aan het Alfa College. Deze
              opleiding heb ik in 2019 afgerond waarna ik ben begonnen aan mijn
              huidige opleiding, Tijdens mijn opleiding aan het MBO heb ik stage
              mogen lopen bij een aantal toffe bedrijven die erg goed aansloten
              bij mijn interesses, hier kon ik mijn kennis en vaardigheden
              vergroten en leren toepassen in de praktijk.<br></br>
              <br></br> Na mijn stage in het 3e jaar MBO ben ik twee en half
              jaar werkzaam geweest bij een communicatiebureau. In deze periode
              heb ik veel geleerd over animatie, drukwerk, webdesign en social
              media. Momenteel ben ik bezig mijzelf te ontwikkelen in het
              front-end aspect en ben ik bezig meer frameworks te leren. Ik ben
              een zelfstandig persoon maar kan goed in teamverband werken en kan
              dan ook moeilijk stil zitten. In mijn vrije tijd ben ik veel bezig
              met het ontwikkelen van websites, fotografie. Verder zijn auto’s
              een grote hobby van mij.
            </p>

            <Nav.Link
              href={myCV}
              target="_blank"
              download
              className={classes.link}
            >
              <Button className={classes.button}>
                CV bekijken{" "}
                <ArrowForwardIosIcon
                  className={classes.iconButton}
                  style={{ fontSize: 16, marginLeft: "20px" }}
                />
              </Button>
            </Nav.Link>
            <div className={classes.socialLink}>
              <Nav.Link
                href="https://www.linkedin.com/in/frank-van-meurs-0b06b717a/"
                className={classes.firstLink}
              >
                <LinkedInIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Linkedin
              </Nav.Link>
              <Nav.Link
                href="https://github.com/FrankvanMeurs"
                className={classes.firstLink}
              >
                <GitHubIcon
                  className={classes.icon}
                  style={{ fontSize: 20, marginRight: "8px" }}
                />
                Github
              </Nav.Link>
            </div>
          </Col>
          <Col data-aos="fade-left" sm={4}>
            <img className={classes.aboutImg} src={AboutImg} alt=""></img>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default AboutpSec;
