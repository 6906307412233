import React, { useEffect } from "react";

import HeaderKI from "./components/HeaderKI";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescKI from "./components/ProjectdescKI";

function Kappe() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderKI />
      <ProjectdescKI />
    </div>
  );
}

export default Kappe;
