import React, { useEffect } from "react";

import HeaderWPtheme from "./components/HeaderWPtheme";

import AOS from "aos";
import "aos/dist/aos.css";

import ProjectdescWPtheme from "./components/ProjectdescWPtheme";

function Branding() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <HeaderWPtheme />
      <ProjectdescWPtheme />
    </div>
  );
}

export default Branding;
