import React from "react";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";

// Pagina's
import Home from "./Home";
import Projects from "./Projects";
import About from "./About";
import Contact from "./Contact";
import Cargobike from "./Cargobike";
import Taxatievergelijker from "./Taxatievergelijker.js";
import Keaps from "./Keaps.js";
import JTF from "./JTF.js";
import WPthemeFrvnk from "./WPtheme.js";
import Websitefrvnk from "./Websitefrvnk.js";
import KappeIntermediair from "./Kappe.js";
import NivobaTechnology from "./Nivoba.js";
import Logos from "./Logos.js";
import Photography from "./Photography.js";
import Roossien from "./Roossien.js";
import Osv from "./Osv.js";

import ScrollToTop from "./ScrollToTop.js";

import "./index.css";
import reactDom from "react-dom";

const App = () => (
  <div>
    {/* <Navbar /> */}

    <div>
      <Router>
        <Switch>
          <ScrollToTop>
            <Navbar />
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route exact path="/home" component={Home} />
            <Route exaxt path="/projects" component={Projects} />
            <Route exaxt path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/Cargobike" component={Cargobike} />
            <Route
              exact
              path="/Taxatievergelijker"
              component={Taxatievergelijker}
            />
            <Route exact path="/Keaps" component={Keaps} />
            <Route exact path="/JoinTheForce" component={JTF} />
            <Route exact path="/Logos" component={Logos} />
            <Route exact path="/Photography" component={Photography} />
            <Route
              exact
              path="/KappeIntermediair"
              component={KappeIntermediair}
            />
            <Route exact path="/wpThemeFrvnk" component={WPthemeFrvnk} />
            <Route exact path="/PortfolioFrvnk" component={Websitefrvnk} />
            <Route
              exact
              path="/NivobaTechnology"
              component={NivobaTechnology}
            />
            <Route exact path="/Roossien" component={Roossien} />
            <Route exact path="/Osv" component={Osv} />
            <Footer />
          </ScrollToTop>
        </Switch>
      </Router>
    </div>
  </div>
);

reactDom.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
