import React from "react";

import Header from "./components/Header";
import AboutmeSec from "./components/AboutmeSec";
import Skills from "./components/Skills";
import RecentProjects from "./components/RecentProjects";

function Home() {
  return (
    <div>
      <Header />
      <AboutmeSec />
      <Skills />
      <RecentProjects />
    </div>
  );
}

export default Home;
