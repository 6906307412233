import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { makeStyles } from "@material-ui/core/styles";
import { LinkContainer } from "react-router-bootstrap";

import Nav from "react-bootstrap/esm/Nav";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import PortfolioImg from "../img/frvnk_taxatievergelijkerthumb.jpg";
import PortfolioImgLong from "../img/frvnk_cargobikethumb.jpg";

import JTF1 from "../img/frvnk_jtf1.jpg";
import JTF2 from "../img/frvnk_jtffw.jpg";
import JTF3 from "../img/frvnk_jtf3.jpg";
import JTF4 from "../img/frvnk_jtf4.jpg";

// import "./Projectdesctest.css";

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg1: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImgFW: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImg3: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg4: {
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "25px",
    width: "100%",
  },
  imageFW: {
    padding: "0",
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "white",
    backgroundColor: "#151515",
    borderRadius: "0",
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },

  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "white",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  rowOne: {
    marginBottom: "4vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
  recentProjects: {
    marginTop: "4vh",
  },
}));

function ProjectdescJTF() {
  const classes = useStyles();

  return (
    <div data-aos="fade-up">
      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Een begrip in de Esports community
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Ongeveer een jaar geleden ben ik benaderd door Join the Force om
              hun website en branding een redesign te geven. Join the Force is
              een welbekend begrip in de Esports community. Voor Join the Force
              heb ik de website en hun social media een opfrisser gegeven.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={12}>
            <img className={classes.projectImg1} src={JTF1} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Ambitie om zo veel mogelijk gamers samen te brengen in een
              gemeenschap waar ze zich thuis voelen.
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className={classes.imageFW}>
            <img className={classes.projectImgFW} src={JTF2} alt=""></img>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>Complete rebranding</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p>
              Zowel de website als de social media kanalen konden wel wat
              verfrissing gebruiken. Hiervoor heb ik templates ontwikkelt in een
              frisse en professionele uitstraling waarmee de organisatie elke
              wedstrijd een post kon maken.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={JTF3} alt=""></img>
          </Col>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={JTF4} alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Bekijk hier<br></br> mijn andere projecten
            </h1>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/Cargobike" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Applicatie | Cargodelivery
                  </h1>
                  <p className={classes.itemCategory}>Web applicatie</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImgLong}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer
              to="/Taxatievergelijker"
              className={classes.galleryLinks}
            >
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Website | Taxatievergelijker
                  </h1>
                  <p className={classes.itemCategory}>Web design</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImg}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectdescJTF;
