import React from "react";
import { Container, Col, Row } from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/esm/Nav";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import PortfolioImg from "../img/frvnk_keapsthumb.jpg";
import PortfolioImgLong from "../img/frvnk_wpthumb.jpg";

import { makeStyles } from "@material-ui/core/styles";

import Cargo1 from "../img/frvnk_cargodelivery1.jpg";
import Cargo2 from "../img/frvnk_cargodelivery2.jpg";
import Cargo3 from "../img/frvnk_cargodelivery3.jpg";
import Cargo4 from "../img/frvnk_cargodelivery4.jpg";

// import "./Projectdesctest.css";

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg1: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImgFW: {
    marginTop: "4vh",
    marginBottom: "4vh",
    width: "100%",
  },
  projectImg3: {
    marginTop: "4vh",
    marginBottom: "4vh",
  },
  projectImg4: {
    marginTop: "4vh",
    marginBottom: "4vh",
    marginRight: "25px",
    width: "100%",
  },
  imageFW: {
    padding: "0",
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portItem: {
    height: "100%",
    filter: "brightness(1)",
    transition: "filter 0.5s ease-in-out",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },

  itemtTitle: {
    position: "absolute",
    bottom: "50px",
    left: "35px",
    color: "var(--text-colorBlack)",
    fontSize: "22px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "30px",
    left: "35px",
    color: "white",
    fontSize: "16px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "45%",
    left: "45%",
    fontSize: "50px",
  },
  rowOne: {
    marginBottom: "4vh",
  },
  gridGallery: {
    marginBottom: "8vh",
  },
  galleryLinks: {
    padding: "0",
    height: "100%",
  },
  recentProjects: {
    marginTop: "4vh",
  },
  text: {
    color: "var(--text-colorBlack)",
  },
}));

function ProjectdescCargobike() {
  const classes = useStyles();

  return (
    <div>
      <Container data-aos="fade-up">
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Inzichtelijk emmissivrij pakketten bezorgen.
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className={classes.text}>
              Afgelopen schooljaar heb ik samen met mijn projectgroep gewerkt
              aan de applicatie voor cargobikes. Hiervoor hebben wij het concept
              CargoDelivery bedacht. Met deze applicatie krijgt de ondernemer,
              bezorger en klant inzicht in hun transport van pakketten. Met deze
              applicatie kan een ondernemer zendingen aanmaken en kan de
              bezorger een route inzien die hij of zij moet gaan rijden.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={12}>
            <img className={classes.projectImg1} src={Cargo1} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Emissievrij pakketten bezorgen in de binnenstad
            </h1>
          </Col>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Col className={classes.imageFW}>
            <img className={classes.projectImgFW} src={Cargo2} alt=""></img>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Een groene oplossing voor een probleem.
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <p className={classes.text}>
              Deze applicatie is gebouwd met het React framework in combinatie
              met Material UI. Om onderscheid te kunnen maken tussen het type
              gebruiker is er gebruik gemaakt van Firebase. In Firebase zijn een
              aantal dingen zoals type gebruiker en welk scherm getoond moet
              worden opgenomen.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Cargo3} alt=""></img>
          </Col>
          <Col xs={12} md={6}>
            <img className={classes.projectImg4} src={Cargo4} alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h1 className={classes.projectTitle}>
              Bekijk hier<br></br> mijn andere projecten
            </h1>
          </Col>
        </Row>

        <Row className={classes.rowOne}>
          <Col xs={12} md={4}>
            <LinkContainer to="/wpThemeFrvnk" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>
                    Website | Portfolio 2021
                  </h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImgLong}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col xs={12} md={8}>
            <LinkContainer to="/Keaps" className={classes.galleryLinks}>
              <Nav.Link>
                <div className={classes.portItem}>
                  <ControlPointIcon className={classes.icon} />
                  <h1 className={classes.itemtTitle}>Website | Keaps</h1>
                  <p className={classes.itemCategory}>Website</p>
                  <img
                    className={classes.portfolioimg}
                    src={PortfolioImg}
                    alt=""
                  ></img>
                </div>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectdescCargobike;
