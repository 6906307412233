import React, { useEffect } from "react";
import "./RecentProjects.css";
import "../App.css";
import styled from "styled-components";

import AOS from "aos";
import "aos/dist/aos.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@material-ui/icons/ControlPoint";

import TaxavSlider from "../img/frvnk_taxatievergelijkerslidethumb.jpg";
import CargobSlider from "../img/frvnk_cargobikeslidethumb.jpg";
import KeapsSlider from "../img/frvnk_keapsslidethumb.jpg";
import jtfSlider from "../img/frvnk_jtfslidethumb.jpg";
import WebSlider from "../img/frvnk_webslidethumb.jpg";
import roosSlider from "../img/frvnk_roosliderthumb.jpg";
import osvSlider from "../img/frvnk_osvsliderthumb.jpg";

import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";

const Scrollbar = styled.div`
  display: flex;
  overflow-x: scroll;
  // margin-left: 14%;
  margin-top: 1%;
  margin-bottom: 3%;
`;

const ScrollbarItem = styled.div`
  margin-right: 20px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // marginLeft: '15%',
    // marginRight: '15%',
    marginTop: "50px",
  },
  card: {
    padding: "0px",
    position: "relative",
    zIndex: "1",
    minWidth: "600px",
    backgroundColor: "#151515",
    marginBottom: "20%",
    height: "320px",
    transition: "filter 0.5s ease-in-out",
    filter: "brightness(1)",
    "&:hover": {
      filter: "brightness(1.5)",
    },
  },
  portfolioimg: {
    width: "100%",
    height: "100%",
  },
  portcat: {
    paddingBottom: "0",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    borderRadius: "0",
    fontSize: "16px",
    boxShadow: "none",
  },
  porttitle: {
    fontWeight: "600",
    padding: "0",
    marginTop: "15px",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "52px",
    boxShadow: "none",
    marginBottom: "20px",
  },
  itemtTitle: {
    position: "absolute",
    bottom: "60px",
    left: "45px",
    color: "white",
    fontSize: "26px",
  },
  itemCategory: {
    position: "absolute",
    bottom: "35px",
    left: "45px",
    color: "white",
    fontSize: "18px",
  },
  icon: {
    color: "white",
    position: "absolute",
    top: "40%",
    left: "45%",
    fontSize: "50px",
  },
  scrollbarWidth: {
    width: "150%",
    marginBottom: "10vh",
  },
  bar: {
    paddingRight: "150%",
    height: "100%",
  },
  button: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "3%",
    minWidth: "20vh",
    minHeight: "4vh",
    textTransform: "none",
    paddingLeft: "17px",
    paddingRight: "17px",
  },
  galleryLinks: {
    padding: "0",
  },
  iconButton: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  link: {
    padding: "0",
  },
}));

function RecentProjects() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Col sm={8}>
            <div className={classes.root}>
              <h3 className={classes.portcat}>recente projecten</h3>
              <h1 className={classes.porttitle}>
                Bekijk hier mijn meest<br></br> recente projecten
              </h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className={classes.scrollbarWidth}>
        <Row data-aos="fade-left">
          <Col sm>
            <Scrollbar className={classes.bar}>
              <ScrollbarItem className={classes.card}>
                <LinkContainer to="/Roossien" className={classes.galleryLinks}>
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>
                        Website | Duurzaamheidstechniek Roossien
                      </h1>
                      <p className={classes.itemCategory}>Website</p>
                      <img
                        className={classes.portfolioimg}
                        src={roosSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>

              <ScrollbarItem className={classes.card}>
                <LinkContainer to="/Cargobike" className={classes.galleryLinks}>
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>Website | OSV</h1>
                      <p className={classes.itemCategory}>Website</p>
                      <img
                        className={classes.portfolioimg}
                        src={osvSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>

              <ScrollbarItem className={classes.card}>
                <LinkContainer
                  to="/Taxatievergelijker"
                  className={classes.galleryLinks}
                >
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>
                        Website | Taxatievergelijker
                      </h1>
                      <p className={classes.itemCategory}>Web design</p>
                      <img
                        className={classes.portfolioimg}
                        src={TaxavSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>

              <ScrollbarItem className={classes.card}>
                <LinkContainer to="/Cargobike" className={classes.galleryLinks}>
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>
                        Applicatie | Cargodelivery
                      </h1>
                      <p className={classes.itemCategory}>Web applicatie</p>
                      <img
                        className={classes.portfolioimg}
                        src={CargobSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>

              <ScrollbarItem className={classes.card}>
                <LinkContainer to="/Keaps" className={classes.galleryLinks}>
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>Website | Keaps</h1>
                      <p className={classes.itemCategory}>Website</p>
                      <img
                        className={classes.portfolioimg}
                        src={KeapsSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>

              <ScrollbarItem className={classes.card}>
                <LinkContainer
                  to="/JoinTheForce"
                  className={classes.galleryLinks}
                >
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>
                        Website | Join The Force
                      </h1>
                      <p className={classes.itemCategory}>Website</p>
                      <img
                        className={classes.portfolioimg}
                        src={jtfSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>

              <ScrollbarItem className={classes.card}>
                <LinkContainer
                  to="/BrandingFrvnk"
                  className={classes.galleryLinks}
                >
                  <Nav.Link>
                    <div>
                      <ControlPointIcon className={classes.icon} />
                      <h1 className={classes.itemtTitle}>
                        Portfolio | Frank van Meurs
                      </h1>
                      <p className={classes.itemCategory}>Website</p>
                      <img
                        className={classes.portfolioimg}
                        src={WebSlider}
                        alt=""
                      ></img>
                    </div>
                  </Nav.Link>
                </LinkContainer>
              </ScrollbarItem>
            </Scrollbar>
          </Col>
        </Row>

        <Row data-aos="fade-up">
          <Col sm>
            <LinkContainer to="/projects">
              <Nav.Link className={classes.link}>
                <Button className={classes.button}>
                  Alle projecten bekijken{" "}
                  <ArrowForwardIosIcon
                    className={classes.iconButton}
                    style={{ fontSize: 16, marginLeft: "20px" }}
                  />
                </Button>
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      {/* 
        <Grid container spacing={0}>
        

            <Grid item md={12} xs={12}>
                    <Scrollbar data-aos="fade-left" className={classes.bar}>
                      <ScrollbarItem className={classes.card}>
                        <div>
                          <ControlPointIcon className={classes.icon} />
                          <h1 className={classes.itemtTitle}>Project type | Project name</h1>
                          <p className={classes.itemCategory}>Category</p>
                          <img className={classes.portfolioimg} src={ PortfolioImg } alt=""></img>
                        </div>
                      </ScrollbarItem>

                      <ScrollbarItem className={classes.card}>
                        <div>
                          <ControlPointIcon className={classes.icon} />
                          <h1 className={classes.itemtTitle}>Project type | Project name</h1>
                          <p className={classes.itemCategory}>Category</p>
                          <img className={classes.portfolioimg} src={ PortfolioImg } alt=""></img>
                        </div>
                    
                  </ScrollbarItem>

                  <ScrollbarItem className={classes.card}>
                        <div>
                          <ControlPointIcon className={classes.icon} />
                          <h1 className={classes.itemtTitle}>Project type | Project name</h1>
                          <p className={classes.itemCategory}>Category</p>
                          <img className={classes.portfolioimg} src={ PortfolioImg } alt=""></img>
                        </div>
                    
                  </ScrollbarItem>
                </Scrollbar>
            </Grid>
        </Grid>  */}
    </div>
  );
}

export default RecentProjects;
