import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";

import Col from "react-bootstrap/Col";

import HeaderImg from "../img/homeheader.jpg";

import { makeStyles } from "@material-ui/core/styles";

import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "absolute",
    top: "31%",
    left: "15%",
    overflowX: "hidden",
  },
  paper: {
    padding: theme.spacing(5.5),
    textAlign: "left",
    color: "white",
    backgroundColor: "#151515",
    borderRadius: "0",
  },
  headerTitle: {
    fontWeight: "600",
    padding: "0",
    marginTop: "15px",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "64px",
    backgroundColor: "#26262600",
    boxShadow: "none",
    marginBottom: "20px",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      fontSize: "42px",
      padding: "15px",
    },
  },
  headerDesc: {
    padding: "0",
    textAlign: "left",
    color: "#000000",
    fontSize: "18px",
    fontWeight: "200",
    backgroundColor: "#26262600",
    boxShadow: "none",
  },
  HomeHeader: {
    position: "relative",
    zIndex: "-2",
    overflowX: "hidden",
    backgroundImage: "var(--header-img)",
    backgroundRepeat: "no-repeat",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      height: "50vh",
      Width: "100%",
    },
  },
  headerText: {
    position: "absolute",
    zIndex: "1",
    top: "325px",
    // left: "20%",
    marginRight: "0",

    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      left: "0%",
      top: "26%",
    },
  },
  headerSpan: {
    color: "#412B76",
  },
  homeVideo: {
    width: "100vw",
  },
  test: {
    height: "100%",
    width: "100%",
  },
}));

function Header() {
  const classes = useStyles();

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <div>
      <Container>
        <div data-aos="fade-up" className={classes.headerText}>
          <Col xs={12} md={12}>
            <h1 className={classes.headerTitle}>
              <span className={classes.headerSpan}>Photography</span> <br></br>
              Other
            </h1>
          </Col>
        </div>
      </Container>

      {/* <img src={HeaderImg} className={classes.HomeHeader} alt=""></img> */}
      <div className={classes.HomeHeader}>
        <img
          height="776px"
          // style={{ width: "100%" }}
          // src={HeaderImg}
          className={classes.HomeHeader}
          alt=""
        />
        {/* <video
          autoPlay
          loop
          muted
          playsinline
          height="776px"
          // objectFit="cover"
        >
          <source src={Homevideo} type="video/mp4" />
        </video> */}
      </div>
    </div>
  );
}

export default Header;
