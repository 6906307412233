import React, { useEffect } from "react";
import emailjs from "emailjs-com";

import HeaderContact from "./components/HeaderContact";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import { makeStyles } from "@material-ui/core/styles";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  contactTitle: {
    fontWeight: "600",
    paddingLeft: "0%",
    padding: "0",
    marginTop: "4vh",
    textAlign: "left",
    color: "var(--text-colorBlack)",
    fontSize: "52px",
    boxShadow: "none",
    marginBottom: "2vh",
  },
  contactDesc: {
    marginBottom: "3vh",
    color: "var(--text-colorBlack)",
  },
  textFieldCustom: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      color: "white",
      borderBottom: "1px solid #412B76",
    },
  },
  inputFields: {
    color: "var(--text-colorBlack)",
    // eslint-disable-next-line
    ["@media (max-width:780px)"]: {
      width: "35ch",
    },
  },
  messageField: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "100%",
      color: "white!important",
      borderBottom: "1px solid #412B76",
    },
    marginTop: "6vh",
    width: "54ch",
  },
  contactForm: {
    marginBottom: "8vh",
  },
  buttonSend: {
    backgroundColor: "#412B76",
    "&:hover": {
      backgroundColor: "#7f5cd4",
    },
    color: "white",
    borderRadius: "0",
    marginTop: "5%",
    minWidth: "16vh",
    minHeight: "4vh",
    textTransform: "none",
  },
  snackBarstyle: {
    "& .MuiAlert-filledSuccess": {
      backgroundColor: "#412B76",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Contact() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9inui9k",
        "template_tcgo9fq",
        e.target,
        "user_xYLK8y5735GCuCoJUuVXQ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    document.getElementById("contactFP").style.visibility = "hidden";
    document.getElementById("contactThanks2").style.display = "none";
    document.getElementById("contactThanks").style.display = "block";
  }

  return (
    <div>
      <HeaderContact />

      <Container className={classes.contactForm}>
        <Row id="contactThanks2">
          <Col data-aos="fade-up" sm={8}>
            <h1 className={classes.contactTitle}>Laten we samenwerken!</h1>
            <p className={classes.contactDesc} data-aos="fade-up">
              Beoordelingen, kritiek, suggesties of vragen? Ik zal zo spoedig
              mogelijk op uw bericht reageren!
            </p>
          </Col>
        </Row>

        <div className={classes.snackBarstyle}>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Je bericht is succesvol verzonden!
            </Alert>
          </Snackbar>
        </div>

        <Row id="contactThanks" style={{ display: "none" }}>
          <Col data-aos="fade-up" sm={8}>
            <h1 className={classes.contactTitle}>Bedankt voor uw bericht!</h1>
            <p className={classes.contactDesc} data-aos="fade-up">
              Ik zal zo spoedig mogelijk op uw bericht reageren!
            </p>
          </Col>
        </Row>

        <form onSubmit={sendEmail} data-aos="fade-up" id="contactFP">
          <Row>
            <Col sm={4}>
              <TextField
                noValidate
                autoComplete="off"
                data-aos="fade-up"
                name="from_name"
                id="standard-basic"
                label="Naam*"
                InputProps={{
                  className: classes.inputFields,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  style: {
                    color: "var(--text-colorBlack)",
                  },
                }}
              />
            </Col>
            <Col sm={4}>
              <TextField
                noValidate
                autoComplete="off"
                data-aos="fade-up"
                id="standard-basic"
                label="Email*"
                name="from_email"
                InputProps={{
                  className: classes.inputFields,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  style: {
                    color: "var(--text-colorBlack)",
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                className={classes.messageField}
                noValidate
                autoComplete="off"
                data-aos="fade-up"
                id="standard-multiline-static"
                label="Jou bericht"
                name="message"
                multiline
                rows={4}
                defaultValue="Typ hier jouw bericht"
                InputProps={{
                  className: classes.inputFields,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  style: {
                    color: "var(--text-colorBlack)",
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <Button
                  onClick={handleClick}
                  className={classes.buttonSend}
                  type="submit"
                >
                  Bericht versturen{" "}
                  <ArrowForwardIosIcon
                    className={classes.icon}
                    style={{ fontSize: 16, marginLeft: "8px" }}
                  />
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
}

export default Contact;
